import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Layout from "../components/Layout/Layout";
//@ts-ignore

import parse from "html-react-parser";
//@ts-ignore

import Notification from "../images/notification.pdf";
import BreadCrumbs from "../BreadCrumbs";
import Certificate from "../images/certificat.svg";
import DownloadPdf from "../images/download_blue.svg";
// @ts-ignore
const typicalInformation = ({ intl, pageContext }) => {
  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA] ">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="mt-[2.5rem] mb-[2rem]">
            <BreadCrumbs
              pageContext={pageContext}
              title="Для медицинских центров"
            />
          </div>

          <div className="flex flex-col  mt-[2rem]">
            <img src={Certificate} alt="" className="w-[12.5rem] h-[7.5rem]" />
            <a href={Notification} download className="">
              <div className="flex mt-[1.25rem]">
                <img src={DownloadPdf} alt="" />
                <p className="ml-[6px] font-medium text-[1rem] text-[#618EE4]">
                  {intl.formatMessage({
                    id: "AboutUs.AboutUsInfo.DownloadPdf",
                  })}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(typicalInformation);
